
/**elemen form验证 */
/**手机号 */
export const validateElePhone = (rule, value, callback) => {
    if (!validaPhone(value)) {
        callback(new Error('请输入正确的手机号'));
    } else {
        callback();
    }
} 
/** 验证码不能为空*/
export const validateCode = (rule, value, callback) => {
    if (!value.trim()) {
        callback(new Error('请输入验证码'));
    } else {
        callback();
    }
}
/** 6 - 16位密码，区分大小写 */
export const validateElePassword = (rule, value, callback) => {
    if (!validaPassword(value)) {
        callback(new Error('请输入6-16位密码，区分大小写'));
    } else {
        callback();
    }
}



/**卡号验证 */
export const validaCart = (value) => {
    const reg = /^([1-9]{1})(\d{14}|\d{18})$/
    return reg.test(value)
}
/**手机号 */
export const validaPhone = (value) => {
    const reg = /^1[3-9]\d{9}$/
    return reg.test(value)
}
/**正整数 */
export const validaInteger = (value) => {
    const reg = /^[1-9]\d*$/
    return reg.test(value)
}
/** 验证中文*/
export const validaZH = (value) => {
    const reg = /[\u4e00-\u9fa5]/
    return reg.test(value)
}
/**6 - 16位密码，区分大小写 */
export const validaPassword = (value) => {
    const reg =  /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
    return reg.test(value)
}
/**判断是不是移动端 */
export const validaMobile = () => {
    return /Android|webOS|iPhone|iPod|BlackBerry|HarmonyOS/i.test(navigator.userAgent)
}

/**判断支付宝账号 */
export const validaAlipay = (value) => {
    const reg = /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/
    return reg.test(value)
}