<template>
    <div class="apply-wrap">
        <div class="apply-min-bg"></div>
        <div class="apply-form">
            <h2>提交申请体验</h2>
            <van-form @submit="onSubmit" ref="applyForm">
                <van-field
                    v-model.trim="fomData.company_name"
                    name="company_name"
                    required
                    placeholder="请填写您的公司名称(完整)"
                    validate-trigger="onBlur"
                    @blur="changeCompnayName"
                />
                <van-field
                    v-model.trim="fomData.industry"
                    name="industry"
                    required
                    placeholder="请填写您的行业"
                />
                <van-field
                    v-model.trim="fomData.name"
                    name="name"
                    required
                    placeholder="请填写您的姓名"
                />
                <van-field
                    v-model.trim="fomData.mobile"
                    name="mobile"
                    required
                    placeholder="请填写您的联系方式"
                    @blur="changeCompnayMobile"
                />
                <van-field
                    v-model.trim="fomData.position"
                    name="position"
                    required
                    placeholder="请填写您的职位"
                />
                <van-field
                    type="number"
                    v-model.trim="fomData.seats_num"
                    name="seats_num"
                    placeholder="请填写需求数量(坐席数量)"
                    required
                />
                <div style="margin: 16px;">
                    <van-button round block type="info" :disabled="isClick" native-type="submit">提交</van-button>
                </div>
            </van-form>

        </div>
    </div>
</template>

<script>
    import { validaInteger, validaZH, validaPhone, validaMobile } from '@/utils/valida'
    import { mapState } from 'vuex'
    import { setStorage, mdSignKey } from '@/utils/common'
    import { systemType } from '@/utils/enum'
    export default {
        data() {
            return {
                fomData: {
                    company_name: '',
                    industry: '',
                    name: '',
                    mobile: '',
                    position: '',
                    seats_num: '',
                    invite_code: ''
                },
                invite_code: '',
                validatorKeys: {
                    industry: '请填写您的行业',
                    name: '请填写您的姓名',
                    mobile: '您填写的手机号码有误',
                    position: '请填写您的职位',
                    seats_num: '需求数量(坐席数量)有误'
                },
                isClick: false,
                isGetCompnayName: true,
                isGetCompnayMobile: true,
                isVerifyName: true
            }
        },
        async created() {
            const { userId, system_type, token } = this.$route.query
            if(token) {
                if(!validaMobile()) {
                    this.$router.replace({ path: '/teamwork', query: { token } })
                    return
                }
                setStorage('token', token)
                const result = await this.$store.dispatch('getUserInfo')
                if(!result) {
                    this.isClick = true
                }
                return
            }


            if(!userId) {
                this.$toast('用户id获取失败')
                return
            }
            if(!systemType.includes(system_type)){
                this.$toast('system_type类型错误')
                return
            }
            if(!validaMobile()) {
                this.$router.replace({ path: '/teamwork', query: { userId, system_type } })
                return
            }
            const result = await this.$store.dispatch('getToken', { userId, isDetails: true, system_type })
            if(!result){
                this.isClick = true
            }

            
            
        },
        computed: {
            ...mapState([ 'userInfo' ])
        },
        methods: {
            async changeCompnayName() {
                this.fomData.company_name = this.fomData.company_name.replace(/\s+/g,'')
                const { code, data } = await this.$postHttp(this.$api.queryCompany, {
                    company_name: this.fomData.company_name
                })
                if(code == 1 && data.code == 1) {
                    //查询到相关企业
                    this.isGetCompnayName = false
                    const result = await this.verifyCompanyInfo('company_name')
                    if(result.code == 0) {
                        this.isVerifyName = true
                    } else {
                        this.isVerifyName = false
                    }
                } else {
                    this.isGetCompnayName = true
                }
            },
            async changeCompnayMobile() {
                if(validaPhone(this.fomData.mobile)){
                    const result = await this.verifyCompanyInfo('mobile')
                    if(result.code == 0) {
                        this.isGetCompnayMobile = true
                    } else {
                        this.isGetCompnayMobile = false
                    }
                }
            },
            verifyCompanyInfo(valKey) {
                const params = {
                    [valKey]: this.fomData[valKey],
                    sign: mdSignKey(this.$store.state.sign_key, this.fomData[valKey], valKey)
                }
                return this.$postHttp(this.$api.verifyCompany,params)
            },
            onSubmit(values) {
                if(this.isClick) return
                if(this.isGetCompnayName) {
                    this.$toast('您输入的企业有误，请核实后重新输入')
                    return
                }
                for (const key in this.validatorKeys) {
                    if(key == 'mobile') {
                        if(!validaPhone(values[key])) {
                            this.$toast(this.validatorKeys[key]);
                            return
                        }
                    } else if(key == 'seats_num' ) {
                        if(!validaInteger(values[key])) {
                            this.$toast(this.validatorKeys[key]);
                            return
                        }
                    } else if(!validaZH(values[key])){
                        this.$toast(this.validatorKeys[key]);
                        return
                    }
                }
                if(this.isVerifyName) {
                    this.$toast('您填写的企业已存在')
                    return
                }
                if(this.isGetCompnayMobile) {
                    this.$toast('您填写的手机号已存在')
                    return
                }
                this.isClick = true
                values.invite_code = this.userInfo.invite_code
                this.$postHttp(this.$api.postApplyEnjoy, values).then(res => {
                    if(res.code == 1) {
                        this.$toast('提交成功');
                        for (const key in this.fomData) {
                            this.fomData[key] = ''
                        }
                    }
                    this.isClick = false
                }).catch(() => {
                    this.isClick = false
                })
              
            }
        }
    }
</script>
<style>
html,body,#app{
    height: 100%;
}
</style>
<style lang="scss" scoped>
.apply-wrap{
    width: 100%;
    height: 100%;
    background: url('../../assets/image/mobile/apply_bg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .apply-min-bg{
        width: 100%;
        height: r(382);
        position:absolute;
        left: 0;
        bottom: 0;
        background: url('../../assets/image/mobile/apply_min_bg.png') no-repeat;
        background-size: 100% 100%;
        z-index: 2;
    }
    .apply-form{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @include wh(r(630),r(950));
        background: #fff;
        border-radius: r(24);
        box-shadow: 0px r(16) r(40) rgba(0, 0, 0, 0.1);
        z-index: 5;
        padding: r(30) r(34);
        h2{
            @include font(r(40));
            margin-bottom: r(r(30));
        }
        ::v-deep .van-cell{
            padding-bottom: 0;
            padding-top: 0;
            margin-top: r(20);
            &:nth-child(6){
                padding-bottom: r(26);
            }
            &::after{
                display: none;
            }
            &::before{
                top: 50%;
                transform: translateY(-50%);
            }
            .van-field__control{
                @include wh(r(500),r(90));
                background: #F9FAFE;
                border-radius: r(8);
                border: r(2) solid #F2F5FF;
                @include font(r(30));
                padding: 0 r(14);
            }
            .van-field__error-message{
                position: absolute;
                bottom: r(-40);
                left: 0;
                transform: scale(0.9);
            }
        }
    }
}
</style>